import * as React from 'react'
import * as Layout from '../layout'
import { styled } from '@mui/material/styles'
import { Box, Button } from "@mui/material";
import WalletSelectModal from '../components/WalletSelectModal'

type Props = {
    onClickConnect: () => void
    dialogOpen: boolean
    selectedConnectionType: 'MetaMask' | 'WalletConnect' | ''
    onCloseDialog: (value: 'MetaMask' | 'WalletConnect' | '') => void
}

export const Component = (props: Props) => {
    return (
        <Layout.Main address={''}>
            <WalletSelectModal
                onClose={props.onCloseDialog}
                open={props.dialogOpen}
                selectedValue={''}
            />
            <ContainerItems  m={2}>
                <Sub1Title>🌇 Event</Sub1Title>
                <Sub3Title>和組DAO 東京オフ会</Sub3Title>
                <Sub1Title>🐈 Description</Sub1Title>
                <Sub3Title>Open wΞlock with your Wagumi Cats!</Sub3Title>
                <ButtonWrapper>
                    <Button
                      fullWidth={true}
                      onClick={props.onClickConnect}
                      variant={'outlined'}
                    >
                        CONNECT WALLET
                    </Button>
                </ButtonWrapper>
            </ContainerItems>
        </Layout.Main>
    )
}

const ContainerItems = styled(Box)``

const Sub1Title = styled('div')`
    font-weight: bold;
    font-size: 18px;
    margin-top: 18px;
    margin-bottom: 12px;
    font-family: YakuHanJP, 'Noto Sans JP', sans-serif;
    color: rgb(121, 121, 121);
`

const Sub3Title = styled('div')`
    font-size: 12px;
    line-height: 12px;
    margin-top: 12px;
    font-family: YakuHanJP, 'Noto Sans JP', sans-serif;
    color: rgb(121, 121, 121);
`

const ButtonWrapper = styled('div')`
    text-align: center;
    margin-top: 20px;
`
