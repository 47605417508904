import * as React from "react";
import * as MainTemplate from "../templates/Main";
import * as SignInTemplate from "../templates/SignIn";
import * as ethers from 'ethers'
import axios from "axios";
import { useAccount, useConnect, useEnsName, useSignMessage } from "wagmi";
import { useSnackbar } from "notistack";
import { SelectChangeEvent } from "@mui/material";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ethereum: any | undefined; // metamask injects global ethereum
  }
}

const apiClient = axios.create({
  baseURL: "https://tick3t-api-v2-sl6bqje65q-uw.a.run.app/",
  headers: {
    Authorization:
      "Bearer 9b2337fee411655e5aa46163ead9477b8a91cb5e54f85b9b298205196983d1b1"
  }
});

const alchemyClient = axios.create({
  baseURL: `https://eth-mainnet.g.alchemy.com/nft/v2/${process.env.REACT_APP_ALCHEMY_API_KEY}/`
});

export type NFT = {
  tokenId: string
  image: string
}

export const Component = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { connector, isConnected, address } = useAccount();
  const { connect, connectors } = useConnect();

  const [width, setWidth] = React.useState<number>(window.innerWidth);
  const [isMobile, setIsMobile] = React.useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [qr, setQr] = React.useState<string>("");
  const [cats, setCats] = React.useState<NFT[]>([]);
  const [tokenId, setTokenId] = React.useState("");

  const { data: ens } = useEnsName({ address: address });

  const { signMessage } = useSignMessage({
    async onSuccess(data, variables) {
      let responseData = (
        await apiClient.post("/issue", {
          signature: data,
          message: JSON.parse(variables.message as string)
        })
      ).data;

      setLoading(false);
      setQr(JSON.stringify(responseData));

      responseData = (await apiClient.post("/verify", responseData)).data;
      console.log(responseData);
    },
    onError() {
      setLoading(false);
      enqueueSnackbar(
        "MetaMask Message Signature: User denied message signature.",
        { variant: "error" }
      );
    }
  });

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  React.useEffect(() => {
    if (width <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  React.useEffect(() => {
    if (address) {
      alchemyClient.get("/getNFTs", {
        params: {
          owner: address,
          "contractAddresses": ["0x6144d927ee371de7e7f8221b596f3432e7a8e6d9"]

        }
      }).then(r => {
        if (r.data && r.data.ownedNfts) {
          const cats = r.data.ownedNfts.map((r: { id: { tokenId: any }; media: { thumbnail: any }[] }) => {
            return {
              tokenId: ethers.BigNumber.from(
                r.id.tokenId
              ).toString(),
              image: r.media[0].thumbnail
            };
          });
          setCats(cats);
        }
      });
    }
  }, [address]);

  const onChangeCat = (event: SelectChangeEvent) => {
      setTokenId(event.target.value)
  };

  const onClickConnect = () => setDialogOpen(true);

  const onSelectConnectionOrDialogClose = React.useCallback(
    (selectedConnectionType: "MetaMask" | "WalletConnect" | "") => {
      setDialogOpen(false);
      if (selectedConnectionType === "") return;
      if (selectedConnectionType === "MetaMask") {
        if (isMobile) {
          if (window.ethereum === undefined) {
            if (window.confirm("Open in the MetaMask app?")) {
              const baseUrl = "dapp://";
              // eslint-disable-next-line
              location.href = `${baseUrl}${location.hostname}${location.pathname}${location.search}`;
              return;
            }
            enqueueSnackbar("MetaMask Should Be Installed", {
              variant: "error"
            });
            return;
          }
        }
        connect({ connector: connectors[0] });
      }
      if (selectedConnectionType === "WalletConnect") {
        console.log(connectors[1])
        connect({ connector: connectors[1] });
      }
    },
    [connect, connectors, enqueueSnackbar, isMobile]
  );

  const onClickSubmit = React.useCallback(async () => {
    if (!tokenId || !address || !connector) return;
    try {
      setLoading(true);
      const { data } = await apiClient.get("/nonce", {
        params: {
          address
        }
      });

      const messageData = {
        issuer: "JRtlKAljmbWD0BX9V7ZF",
        event_id: "23usvjDL751j6um9TtZT",
        nonce: data.nonce,
        nft: {
          contract_address:
            '0x6144d927ee371de7e7f8221b596f3432e7a8e6d9',
          token_id: tokenId
        }

      };

      const message = JSON.stringify(messageData);
      signMessage({ message });
    } catch (err) {
      setLoading(false);
    }
  }, [tokenId, address, connector, signMessage]);

  return React.useMemo(() => {
    if (!isConnected) {
      return (
        <SignInTemplate.Component
          onClickConnect={onClickConnect}
          dialogOpen={dialogOpen}
          onCloseDialog={onSelectConnectionOrDialogClose}
          selectedConnectionType={""}
        />
      );
    } else {
      return (
        <MainTemplate.Component
          address={ens || address}
          ens={ens || ""}
          qr={qr}
          onSubmit={onClickSubmit}
          loading={loading || false}
          onChangeCat={onChangeCat}
          tokenId={tokenId}
          cats={cats}
        />
      );
    }
  }, [
    address,
    dialogOpen,
    ens,
    isConnected,
    loading,
    onClickSubmit,
    onSelectConnectionOrDialogClose,
    qr,
    tokenId,
    cats
  ]);
};
