import * as React from "react";
import * as Layout from "../layout";
import { styled } from "@mui/material/styles";
import {
  Box,
  Backdrop,
  CircularProgress,
  Stack,
  FormControl, InputLabel, MenuItem, Select, SelectChangeEvent
} from "@mui/material";
import { Button } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { NFT } from "../pages/Main";

type Props = {
  address?: string
  loading: boolean
  qr?: string
  ens: string
  onChangeCat: (event: SelectChangeEvent) => void
  onSubmit: () => void
  tokenId: string
  cats: NFT[]
}

export const Component = (props: Props) => {

  const SelectOption = React.useMemo(() => {
    return props.cats.map(r=>
      (<MenuItem key={r.tokenId} value={r.tokenId}>{r.tokenId}</MenuItem>)
    )
  }, [props.cats])


  const QRorButton = React.useMemo(() => {
    if (!props.qr)
      return (
        <ButtonWrapper>
          <Button
            variant="outlined"
            onClick={props.onSubmit}
            fullWidth
          >
            Create a ticket
          </Button>
        </ButtonWrapper>
      );
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <QRCodeWrapper
          sx={{
            color: "white",
            mt: 2,
            borderRadius: 1,
            textAlign: "center",
            fontSize: "1rem",
            fontWeight: "700"
          }}
        >
          <QRCodeSVG value={props.qr} size={320} />
        </QRCodeWrapper>
      </Stack>
    );
  }, [props.onSubmit, props.qr]);

  return (
    <Layout.Main address={props.address}>
      <ContainerItems m={2}>
        <StyledBackDrop open={props.loading}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <TitleBackdrop>Signing message..</TitleBackdrop>
            <CircularProgress color="inherit" />
          </Stack>
        </StyledBackDrop>
        <Sub1Title>🌇 Event</Sub1Title>
        <Sub3Title>和組DAO 東京オフ会</Sub3Title>
        <Sub1Title>💎 Description</Sub1Title>
        <Sub3Title>Open wΞlock with your Wagumi Cats!</Sub3Title>
        <Sub1Title>🐈 Your Cat</Sub1Title>
        <FormControl fullWidth>
          <InputLabel id="cats">Select Wagumi Cats</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.tokenId}
            label="your cat"
            onChange={props.onChangeCat}
          >
            {SelectOption}
          </Select>
        </FormControl>

        {QRorButton}
      </ContainerItems>
    </Layout.Main>
  );
};

const ContainerItems = styled(Box)``;

const StyledBackDrop = styled(Backdrop)`
    z-index: 1001;
    color: '#fff';
`;

const TitleBackdrop = styled("div")`
    z-index: 1002;
    color: white;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-family: YakuHanJP, 'Noto Sans JP', sans-serif;
`;

const Sub1Title = styled("div")`
    font-weight: bold;
    font-size: 18px;
    margin-top: 18px;
    margin-bottom: 12px;
    font-family: YakuHanJP, 'Noto Sans JP', sans-serif;
    color: rgb(121, 121, 121);
`;

const Sub3Title = styled("div")`
    font-size: 12px;
    line-height: 12px;
    margin-top: 12px;
    font-family: YakuHanJP, 'Noto Sans JP', sans-serif;
    color: rgb(121, 121, 121);
`;

const ButtonWrapper = styled("div")`
    text-align: center;
    margin-top: 20px;
`;

const QRCodeWrapper = styled(Box)``;
