import 'regenerator-runtime/runtime'

import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { SnackbarProvider } from 'notistack'

import Route from './route'
import {
    chain,
    WagmiConfig,
    configureChains,
    createClient,
    defaultChains
} from 'wagmi'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import './modules/firebase'
import { getAnalytics } from 'firebase/analytics'

getAnalytics()

const { chains, provider, webSocketProvider } = configureChains([...defaultChains, chain.polygon], [
    alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY })
])

const client = createClient({
    autoConnect: true,
    connectors: [
        new MetaMaskConnector({ chains }),
        new WalletConnectConnector({
            chains,
            options: {
                qrcode: true,
                qrcodeModalOptions: {
                    desktopLinks: ['ledger', 'tokenary', 'wallet', 'wallet 3'],
                    mobileLinks: ['metamask', 'rainbow', 'argent', 'trust']
                }
            }
        })
    ],
    provider,
    webSocketProvider
})

const App = () => (
    <SnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
        <WagmiConfig client={client}>
            <Route />
        </WagmiConfig>
    </SnackbarProvider>
)

// @ts-ignore
const root = createRoot(document.getElementById('root'))
root.render(<App />)
